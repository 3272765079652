import React, { FunctionComponent } from 'react';
import Cookies from 'js-cookie';
import { decodeFromBase64 } from '@utils/misc.utils';
import { CarJourneyType } from '../services';
import { FEATURE_SWITCHES_COOKIE_NAME } from '../constants/main';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';
import { BANK_CHECKOUT_JOURNEY_TYPES, CONTACT_OPTIONS } from '../constants/enums';
import { IExperimentalFeatureFlagContext } from '@utils/experiments/@types';

export enum IFinanceWidgetButtonType {
    LINK = 'link',
    ICON = 'icon',
    DEFAULT = 'default',
}

export enum IFinanceWidgetModalType {
    CENTER = 'center',
    SIDEBAR = 'sidebar',
}

export interface IFeatureSwitchContext {
    [key: string]: any;
    FEATURE_SWITCH_BUDGET_CHANGE_ALLOWED?: boolean;
    FEATURE_SWITCH_SOL_ENABLED?: boolean;
    FEATURE_SWITCH_DEALER_LAYER_ENABLED?: boolean;
    FEATURE_SWITCH_BENEFITS_MODE_ENABLED?: boolean;
    FEATURE_SWITCH_DISABLE_SAVE_PX_TO_MOP?: boolean;
    FEATURE_SWITCH_CHECKOUT_PAYMENT_MOCK_ENABLED?: boolean;
    FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS?: CarJourneyType[];
    FEATURE_SWITCH_PX_ENABLED?: boolean;
    FEATURE_SWITCH_PX_FINALIZATION_ENABLED?: boolean;
    FEATURE_SWITCH_LANGUAGE_CONTROLS?: boolean;
    FEATURE_SWITCH_SPLUNK_ENABLED?: boolean;
    FEATURE_SWITCH_DYNAMIC_YIELD_ENABLED?: boolean;
    FEATURE_SWITCH_DYNAMIC_YIELD_DEBUGGING_ENABLED?: boolean;
    FEATURE_SWITCH_PROFORMA_CONSENT?: boolean;
    FEATURE_SWITCH_SHOW_ADDITIONAL_PROFORMA_LEGAL_CONSENTS?: boolean;
    FEATURE_SWITCH_PX_IGNORE_PROMO_CASH?: boolean;
    FEATURE_SWITCH_PX_IGNORE_PROMO_FINANCE?: boolean;
    FEATURE_SWITCH_ENV_SWITCHER_ENABLED?: boolean;
    FEATURE_SWITCH_SHOW_CATEGORIES_IN_OPTIONS_SELECT?: boolean;
    FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY?: PaymentJourneyTypes;
    FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK?: PaymentJourneyTypes;
    FEATURE_SWITCH_CHECKOUT_FINANCE_ENABLED?: boolean;
    FEATURE_SWITCH_CHECKOUT_CONTRACT_ENABLED?: boolean;
    FEATURE_SWITCH_DISABLED_FINANCE_WIDGET?: boolean;
    FEATURE_SWITCH_REMOVE_PX_ON_PAYMENT_CHANGE?: boolean;
    FEATURE_SWITCH_FORCE_OPTIONS_IN_CASH?: boolean;
    FEATURE_SWITCH_FORCE_LOCALIZATION_ENABLED?: boolean;
    FEATURE_SWITCH_SIMULATE_NO_CAR_AVAILABLE_ON_STOCK?: boolean;
    FEATURE_SWITCH_BANK_CHECKOUT_JOURNEY_TYPES?: BANK_CHECKOUT_JOURNEY_TYPES[];
    FEATURE_SWITCH_CONTACT_OPTIONS?: CONTACT_OPTIONS[];
    FEATURE_SWITCH_BANK_CHECKOUT_DEFAULT_JOURNEY_TYPE?: BANK_CHECKOUT_JOURNEY_TYPES;
    FEATURE_SWITCH_REDIRECT_TO_STOCK_ENABLED?: boolean;
    FEATURE_SWITCH_REDIRECT_TO_MTO_ENABLED?: boolean;
    FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_STOCK?: boolean;
    FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_MTO?: boolean;
    FEATURE_SWITCH_CUSTOM_DEPOSIT_TEXT_ALLOWED?: boolean;
    FEATURE_SWITCH_SHOW_PAYMENT_DEPOSIT?: boolean;
    FEATURE_SWITCH_APPLY_MAXIMUM_POWER?: boolean;
    FEATURE_SWITCH_FINANCE_QUOTE_MISSING_404?: boolean;
    FEATURE_SWITCH_SHOW_ANNUAL_MILEAGE?: boolean;
    FEATURE_SWITCH_DISPLAY_CATALOGUE_PRICE?: boolean;
    FEATURE_SWITCH_BASKET_LEGAL_TERMS_AND_CONDITIONS?: boolean;
    FEATURE_SWITCH_STOCK_BASKET_SAVE_ITEM?: boolean;
    FEATURE_SWITCH_CONTACT_DETAILS_ENABLED?: boolean;
    FEATURE_SWITCH_DEMO_VEHICLES_ENABLED?: boolean;
    FEATURE_SWITCH_SHOW_TAN_AND_TAEG?: boolean;
    FEATURE_SWITCH_SHOW_REST_OF_DEALER_MARKERS?: boolean;
    FEATURE_SWITCH_IS_FINANCE_DISABLED?: boolean;
    FEATURE_SWITCH_IS_CASH_DISABLED?: boolean;
    FEATURE_SWITCH_IS_STOCK_SLICE_ENABLED?: boolean;
    FEATURE_SWITCH_SHARE_CONFIGURATION_ENABLED?: boolean;
    FEATURE_SWITCH_IS_STOCK_SUMMARY_ENABLED?: boolean;
    FEATURE_SWITCH_IS_MTO_SUMMARY_ENABLED?: boolean;
    FEATURE_SWITCH_IS_UPSELL_OPTIONS_SECTION_ENABLED?: boolean;
    FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED?: boolean;
    FEATURE_SWITCH_STOCK_DEALER_SHARE_CONFIGURATION_ENABLED?: boolean;
    FEATURE_SWITCH_MTO_DEALER_SHARE_CONFIGURATION_ENABLED?: boolean;
    FEATURE_SWITCH_DEALER_SEARCH_SHARE_CONFIGURATION_ENABLED?: boolean;
    FEATURE_SWITCH_REINSURANCE_INFO_ENABLED?: boolean;
    FEATURE_SWITCH_SAVE_BASKET_CTA_DISABLED?: boolean;
    FEATURE_SWITCH_SHOW_CO2_IMAGE?: boolean;
    FEATURE_SWITCH_CHECK_SESSION_CHANGE_ENABLED?: boolean;
    FEATURE_SWITCH_CARTE_GRISE_ENABLED?: boolean;
    FEATURE_SWITCH_MY_ACCOUNT_ORDERS_DISABLED?: boolean;
    FEATURE_SWITCH_SUMMARY_DETAILS_ON_DEALER_PAGE_DISABLED?: boolean;
    FEATURE_SWITCH_SHOW_REMAINING_TO_PAY_INFO?: boolean;
    FEATURE_SWITCH_PROMO_ENABLED?: boolean;
    FEATURE_SWITCH_LANGUAGE_SWITCHER_ENABLED?: boolean;
    FEATURE_SWITCH_TIRES_LABELLING_ENABLED?: boolean;
    FEATURE_SWITCH_LEAD_TIME_ENABLED?: boolean;
    FEATURE_SWITCH_TRIM_COMPARATOR_ENABLED?: boolean;
    FEATURE_SWITCH_LEGAL_CONSENT_VERSION?: 'v1' | 'v2';
    FEATURE_SWITCH_PX_VERSION?: 'v1' | 'v2';
    FEATURE_SWITCH_CONFIGURABLE_TRIM_PAGE_VERSION?: 'v1' | 'v2';
    FEATURE_SWITCH_AUTOMATIC_OPENING_FINANCE_WIDGET_ON_BASKET_PAGE_ENABLED?: boolean;
    FEATURE_SWITCH_FINANCE_WIDGET_VERSION?: 'v1' | 'v2';
    FEATURE_SWITCH_FINANCE_WIDGET_SHADOW_DOM_ENABLED?: boolean;
    FEATURE_SWITCH_FINANCE_WIDGET_TYPE?: IFinanceWidgetModalType;
    FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON?: IFinanceWidgetButtonType;
    FEATURE_SWITCH_MANDATORY_OPTIONS?: string;
    FEATURE_SWITCH_RENTING_PRODUCTS?: string;
    FEATURE_SWITCH_EXTERNAL_REDIRECT_URL_FOR_HOMEPAGE_LOGO?: string;
    FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES?: string;
    FEATURE_SWITCH_OFFER_VALIDITY_DATE_ENABLED?: boolean;
    FEATURE_SWITCH_CATALAN_ZIP_PREFIXES?: string;
    FEATURE_SWITCH_NIF_ENABLED?: boolean;
    FEATURE_SWITCH_SHOW_FINANCE_LEGAL?: boolean;
    FEATURE_SWITCH_IS_BASKET_STEPS_ENABLED?: boolean;
    FEATURE_SWITCH_IS_LEASYS_ENABLED?: boolean;
    FEATURE_SWITCH_IS_C2S_ENABLED_FOR_DEAL_ACTIVATION?: boolean;
    FEATURE_SWITCH_ENABLE_COLLECT_CONSENT?: boolean;
    FEATURE_SWITCH_PROPAGATE_PROMO_CODE_CUSTOMER_TYPE?: boolean;
    FEATURE_SWITCH_ENGINE_COMPARATOR?: boolean;
    FEATURE_SWITCH_POPULATE_CARTE_GRISE?: boolean;
    FEATURE_SWITCH_GIGYA_ENABLED?: boolean;
    FEATURE_SWITCH_SHOW_PRICE_EXCLUDED_VAT?: boolean;
    FEATURE_SWITCH_SEND_LEAD_TO_CUSTOMER_FIRST_CRM?: boolean;
    FEATURE_SWITCH_POSTHOG_ENABLED?: boolean;
    FEATURE_SWITCH_POSTHOG_IS_AUTOCAPTURE_ENABLED?: boolean;
    FEATURE_SWITCH_AGENT_LOGIN_ENABLED?: boolean;
}

export enum FEATURES_LIST {
    FEATURE_SWITCH_BUDGET_CHANGE_ALLOWED = 'FEATURE_SWITCH_BUDGET_CHANGE_ALLOWED',
    FEATURE_SWITCH_SOL_ENABLED = 'FEATURE_SWITCH_SOL_ENABLED',
    FEATURE_SWITCH_DEALER_LAYER_ENABLED = 'FEATURE_SWITCH_DEALER_LAYER_ENABLED',
    FEATURE_SWITCH_BENEFITS_MODE_ENABLED = 'FEATURE_SWITCH_BENEFITS_MODE_ENABLED',
    FEATURE_SWITCH_DISABLE_SAVE_PX_TO_MOP = 'FEATURE_SWITCH_DISABLE_SAVE_PX_TO_MOP',
    FEATURE_SWITCH_CHECKOUT_PAYMENT_MOCK_ENABLED = 'FEATURE_SWITCH_CHECKOUT_PAYMENT_MOCK_ENABLED',
    FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS = 'FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS',
    FEATURE_SWITCH_PX_ENABLED = 'FEATURE_SWITCH_PX_ENABLED',
    FEATURE_SWITCH_PX_FINALIZATION_ENABLED = 'FEATURE_SWITCH_PX_FINALIZATION_ENABLED',
    FEATURE_SWITCH_PX_IGNORE_PROMO_CASH = 'FEATURE_SWITCH_PX_IGNORE_PROMO_CASH',
    FEATURE_SWITCH_PX_IGNORE_PROMO_FINANCE = 'FEATURE_SWITCH_PX_IGNORE_PROMO_FINANCE',
    FEATURE_SWITCH_LANGUAGE_CONTROLS = 'FEATURE_SWITCH_LANGUAGE_CONTROLS',
    FEATURE_SWITCH_SPLUNK_ENABLED = 'FEATURE_SWITCH_SPLUNK_ENABLED',
    FEATURE_SWITCH_DYNAMIC_YIELD_ENABLED = 'FEATURE_SWITCH_DYNAMIC_YIELD_ENABLED',
    FEATURE_SWITCH_DYNAMIC_YIELD_DEBUGGING_ENABLED = 'FEATURE_SWITCH_DYNAMIC_YIELD_DEBUGGING_ENABLED',
    FEATURE_SWITCH_PROFORMA_CONSENT = 'FEATURE_SWITCH_PROFORMA_CONSENT',
    FEATURE_SWITCH_SHOW_ADDITIONAL_PROFORMA_LEGAL_CONSENTS = 'FEATURE_SWITCH_SHOW_ADDITIONAL_PROFORMA_LEGAL_CONSENTS',
    FEATURE_SWITCH_ENV_SWITCHER_ENABLED = 'FEATURE_SWITCH_ENV_SWITCHER_ENABLED',
    FEATURE_SWITCH_SHOW_CATEGORIES_IN_OPTIONS_SELECT = 'FEATURE_SWITCH_SHOW_CATEGORIES_IN_OPTIONS_SELECT',
    FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY = 'FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY',
    FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK = 'FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK',
    FEATURE_SWITCH_CHECKOUT_FINANCE_ENABLED = 'FEATURE_SWITCH_CHECKOUT_FINANCE_ENABLED',
    FEATURE_SWITCH_CHECKOUT_CONTRACT_ENABLED = 'FEATURE_SWITCH_CHECKOUT_CONTRACT_ENABLED',
    FEATURE_SWITCH_BRAND_RECOMMENDS_ENABLED = 'FEATURE_SWITCH_BRAND_RECOMMENDS_ENABLED',
    FEATURE_SWITCH_BRAND_RECOMMENDS_FINANCE_JOURNEY_PSA_CHECKOUT = 'FEATURE_SWITCH_BRAND_RECOMMENDS_FINANCE_JOURNEY_PSA_CHECKOUT',
    FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT = 'FEATURE_SWITCH_BRAND_RECOMMENDS_DEALER_SELECTION_PSA_CHECKOUT',
    FEATURE_SWITCH_PROMO_CODES_ENABLED = 'FEATURE_SWITCH_PROMO_CODES_ENABLED',
    FEATURE_SWITCH_LANGUAGE_SWITCHER_ENABLED = 'FEATURE_SWITCH_LANGUAGE_SWITCHER_ENABLED',
    FEATURE_SWITCH_PLAUSIBLE_HEAD_SCRIPT_ENABLED = 'FEATURE_SWITCH_PLAUSIBLE_HEAD_SCRIPT_ENABLED',
    FEATURE_SWITCH_PLAUSIBLE_HEAD_SCRIPT_DATA_DOMAIN = 'FEATURE_SWITCH_PLAUSIBLE_HEAD_SCRIPT_DATA_DOMAIN',
    FEATURE_SWITCH_DISABLED_FINANCE_WIDGET = 'FEATURE_SWITCH_DISABLED_FINANCE_WIDGET',
    FEATURE_SWITCH_REMOVE_PX_ON_PAYMENT_CHANGE = 'FEATURE_SWITCH_REMOVE_PX_ON_PAYMENT_CHANGE',
    FEATURE_SWITCH_FORCE_OPTIONS_IN_CASH = 'FEATURE_SWITCH_FORCE_OPTIONS_IN_CASH',
    FEATURE_SWITCH_FORCE_LOCALIZATION_ENABLED = 'FEATURE_SWITCH_FORCE_LOCALIZATION_ENABLED',
    FEATURE_SWITCH_SIMULATE_NO_CAR_AVAILABLE_ON_STOCK = 'FEATURE_SWITCH_SIMULATE_NO_CAR_AVAILABLE_ON_STOCK',
    FEATURE_SWITCH_BANK_CHECKOUT_JOURNEY_TYPES = 'FEATURE_SWITCH_BANK_CHECKOUT_JOURNEY_TYPES',
    FEATURE_SWITCH_CONTACT_OPTIONS = 'FEATURE_SWITCH_CONTACT_OPTIONS',
    FEATURE_SWITCH_BANK_CHECKOUT_DEFAULT_JOURNEY_TYPE = 'FEATURE_SWITCH_BANK_CHECKOUT_DEFAULT_JOURNEY_TYPE',
    FEATURE_SWITCH_REDIRECT_TO_STOCK_ENABLED = 'FEATURE_SWITCH_REDIRECT_TO_STOCK_ENABLED',
    FEATURE_SWITCH_REDIRECT_TO_MTO_ENABLED = 'FEATURE_SWITCH_REDIRECT_TO_MTO_ENABLED',
    FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_STOCK = 'FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_STOCK',
    FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_MTO = 'FEATURE_SWITCH_ENABLE_FORM_LEADS_TO_CRM_MTO',
    FEATURE_SWITCH_CUSTOM_DEPOSIT_TEXT_ALLOWED = 'FEATURE_SWITCH_CUSTOM_DEPOSIT_TEXT_ALLOWED',
    FEATURE_SWITCH_SHOW_PAYMENT_DEPOSIT = 'FEATURE_SWITCH_SHOW_PAYMENT_DEPOSIT',
    FEATURE_SWITCH_SHOW_CO2_VALUES = 'FEATURE_SWITCH_SHOW_CO2_VALUES',
    FEATURE_SWITCH_APPLY_MAXIMUM_POWER = 'FEATURE_SWITCH_APPLY_MAXIMUM_POWER',
    FEATURE_SWITCH_APPLY_ADMINISTRATIVE_POWER = 'FEATURE_SWITCH_APPLY_ADMINISTRATIVE_POWER',
    FEATURE_SWITCH_FINANCE_WIDGET_OMEGA_ENABLED = 'FEATURE_SWITCH_FINANCE_WIDGET_OMEGA_ENABLED',
    FEATURE_SWITCH_DEALER_FEES_ENABLED = 'FEATURE_SWITCH_DEALER_FEES_ENABLED',
    FEATURE_SWITCH_CARTE_GRISE_ENABLED = 'FEATURE_SWITCH_CARTE_GRISE_ENABLED',
    FEATURE_SWITCH_FINANCE_QUOTE_MISSING_404 = 'FEATURE_SWITCH_FINANCE_QUOTE_MISSING_404',
    FEATURE_SWITCH_SHOW_ANNUAL_MILEAGE = 'FEATURE_SWITCH_SHOW_ANNUAL_MILEAGE',
    FEATURE_SWITCH_DISPLAY_CATALOGUE_PRICE = 'FEATURE_SWITCH_DISPLAY_CATALOGUE_PRICE',
    FEATURE_SWITCH_BASKET_LEGAL_TERMS_AND_CONDITIONS = 'FEATURE_SWITCH_BASKET_LEGAL_TERMS_AND_CONDITIONS',
    FEATURE_SWITCH_STOCK_BASKET_SAVE_ITEM = 'FEATURE_SWITCH_STOCK_BASKET_SAVE_ITEM',
    FEATURE_SWITCH_STOCK_SHOW_GR_BODY_STYLE_IN_CAR_TITLE = 'FEATURE_SWITCH_STOCK_SHOW_GR_BODY_STYLE_IN_CAR_TITLE',
    FEATURE_SWITCH_IS_SALESMAN_ID_ENABLED = 'FEATURE_SWITCH_IS_SALESMAN_ID_ENABLED',
    FEATURE_SWITCH_SEND_LEAD_TO_CUSTOMER_FIRST_CRM = 'FEATURE_SWITCH_SEND_LEAD_TO_CUSTOMER_FIRST_CRM',
    FEATURE_SWITCH_CONTACT_DETAILS_ENABLED = 'FEATURE_SWITCH_CONTACT_DETAILS_ENABLED',
    FEATURE_SWITCH_DEMO_VEHICLES_ENABLED = 'FEATURE_SWITCH_DEMO_VEHICLES_ENABLED',
    FEATURE_SWITCH_SHOW_TAN_AND_TAEG = 'FEATURE_SWITCH_SHOW_TAN_AND_TAEG',
    FEATURE_SWITCH_SHOW_ECO_BONUS = 'FEATURE_SWITCH_SHOW_ECO_BONUS',
    FEATURE_SWITCH_SHOW_REST_OF_DEALER_MARKERS = 'FEATURE_SWITCH_SHOW_REST_OF_DEALER_MARKERS',
    FEATURE_SWITCH_IS_FINANCE_DISABLED = 'FEATURE_SWITCH_IS_FINANCE_DISABLED',
    FEATURE_SWITCH_IS_CASH_DISABLED = 'FEATURE_SWITCH_IS_CASH_DISABLED',
    FEATURE_SWITCH_IS_STOCK_SORT_BOX_DISABLED = 'FEATURE_SWITCH_IS_STOCK_SORT_BOX_DISABLED',
    FEATURE_SWITCH_IS_STOCK_SLICE_ENABLED = 'FEATURE_SWITCH_IS_STOCK_SLICE_ENABLED',
    FEATURE_SWITCH_SHARE_CONFIGURATION_ENABLED = 'FEATURE_SWITCH_SHARE_CONFIGURATION_ENABLED',
    FEATURE_SWITCH_IS_STOCK_SUMMARY_ENABLED = 'FEATURE_SWITCH_IS_STOCK_SUMMARY_ENABLED',
    FEATURE_SWITCH_IS_MTO_SUMMARY_ENABLED = 'FEATURE_SWITCH_IS_MTO_SUMMARY_ENABLED',
    FEATURE_SWITCH_IS_UPSELL_OPTIONS_SECTION_ENABLED = 'FEATURE_SWITCH_IS_UPSELL_OPTIONS_SECTION_ENABLED',
    FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED = 'FEATURE_SWITCH_IS_DEALER_CHOICE_ENABLED',
    FEATURE_SWITCH_STOCK_DEALER_SHARE_CONFIGURATION_ENABLED = 'FEATURE_SWITCH_STOCK_DEALER_SHARE_CONFIGURATION_ENABLED',
    FEATURE_SWITCH_MTO_DEALER_SHARE_CONFIGURATION_ENABLED = 'FEATURE_SWITCH_MTO_DEALER_SHARE_CONFIGURATION_ENABLED',
    FEATURE_SWITCH_DEALER_SEARCH_SHARE_CONFIGURATION_ENABLED = 'FEATURE_SWITCH_DEALER_SEARCH_SHARE_CONFIGURATION_ENABLED',
    FEATURE_SWITCH_REINSURANCE_INFO_ENABLED = 'FEATURE_SWITCH_REINSURANCE_INFO_ENABLED',
    FEATURE_SWITCH_SAVE_BASKET_CTA_DISABLED = 'FEATURE_SWITCH_SAVE_BASKET_CTA_DISABLED',
    FEATURE_SWITCH_SHOW_CO2_IMAGE = 'FEATURE_SWITCH_SHOW_CO2_IMAGE',
    FEATURE_SWITCH_CHECK_SESSION_CHANGE_ENABLED = 'FEATURE_SWITCH_CHECK_SESSION_CHANGE_ENABLED',
    FEATURE_SWITCH_STOCK_CASH_USE_EXTERNAL_CHECKOUT = 'FEATURE_SWITCH_STOCK_CASH_USE_EXTERNAL_CHECKOUT',
    FEATURE_SWITCH_STOCK_FINANCE_USE_EXTERNAL_CHECKOUT = 'FEATURE_SWITCH_STOCK_FINANCE_USE_EXTERNAL_CHECKOUT',
    FEATURE_SWITCH_MTO_CASH_USE_EXTERNAL_CHECKOUT = 'FEATURE_SWITCH_MTO_CASH_USE_EXTERNAL_CHECKOUT',
    FEATURE_SWITCH_MTO_FINANCE_USE_EXTERNAL_CHECKOUT = 'FEATURE_SWITCH_MTO_FINANCE_USE_EXTERNAL_CHECKOUT',
    FEATURE_SWITCH_MY_ACCOUNT_ORDERS_DISABLED = 'FEATURE_SWITCH_MY_ACCOUNT_ORDERS_DISABLED',
    FEATURE_SWITCH_SUMMARY_DETAILS_ON_DEALER_PAGE_DISABLED = 'FEATURE_SWITCH_SUMMARY_DETAILS_ON_DEALER_PAGE_DISABLED',
    FEATURE_SWITCH_SHOW_REMAINING_TO_PAY_INFO = 'FEATURE_SWITCH_SHOW_REMAINING_TO_PAY_INFO',
    FEATURE_SWITCH_SHOW_SCRAPPAGE_MODAL = 'FEATURE_SWITCH_SHOW_SCRAPPAGE_MODAL',
    FEATURE_SWITCH_PROMO_ENABLED = 'FEATURE_SWITCH_PROMO_ENABLED',
    FEATURE_SWITCH_TIRES_LABELLING_ENABLED = 'FEATURE_SWITCH_TIRES_LABELLING_ENABLED',
    FEATURE_SWITCH_LEAD_TIME_ENABLED = 'FEATURE_SWITCH_LEAD_TIME_ENABLED',
    FEATURE_SWITCH_TRIM_COMPARATOR_ENABLED = 'FEATURE_SWITCH_TRIM_COMPARATOR_ENABLED',
    FEATURE_SWITCH_LEGAL_CONSENT_VERSION = 'FEATURE_SWITCH_LEGAL_CONSENT_VERSION',
    FEATURE_SWITCH_PX_VERSION = 'FEATURE_SWITCH_PX_VERSION',
    FEATURE_SWITCH_FINANCE_WIDGET_TYPE = 'FEATURE_SWITCH_FINANCE_WIDGET_TYPE',
    FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON = 'FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON',
    FEATURE_SWITCH_MANDATORY_OPTIONS = 'FEATURE_SWITCH_MANDATORY_OPTIONS',
    FEATURE_SWITCH_RENTING_PRODUCTS = 'FEATURE_SWITCH_RENTING_PRODUCTS',
    FEATURE_SWITCH_EXTERNAL_REDIRECT_URL_FOR_HOMEPAGE_LOGO = 'FEATURE_SWITCH_EXTERNAL_REDIRECT_URL_FOR_HOMEPAGE_LOGO',
    FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES = 'FEATURE_SWITCH_EXCLUDED_CHARACTERISTICS_CATEGORIES',
    FEATURE_SWITCH_OFFER_VALIDITY_DATE_ENABLED = 'FEATURE_SWITCH_OFFER_VALIDITY_DATE_ENABLED',
    FEATURE_SWITCH_CATALAN_ZIP_PREFIXES = 'FEATURE_SWITCH_CATALAN_ZIP_PREFIXES',
    FEATURE_SWITCH_NIF_ENABLED = 'FEATURE_SWITCH_NIF_ENABLED',
    FEATURE_SWITCH_SHOW_FINANCE_LEGAL = 'FEATURE_SWITCH_SHOW_FINANCE_LEGAL',
    FEATURE_SWITCH_IS_BASKET_STEPS_ENABLED = 'FEATURE_SWITCH_IS_BASKET_STEPS_ENABLED',
    FEATURE_SWITCH_IS_LEASYS_ENABLED = 'FEATURE_SWITCH_IS_LEASYS_ENABLED',
    FEATURE_SWITCH_IS_C2S_ENABLED_FOR_DEAL_ACTIVATION = 'FEATURE_SWITCH_IS_C2S_ENABLED_FOR_DEAL_ACTIVATION',
    FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_MTO = 'FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_MTO',
    FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_STOCK = 'FEATURE_SWITCH_ENABLE_COLLECT_CONSENT_FORM_LEADS_TO_CRM_STOCK',
    FEATURE_SWITCH_PROPAGATE_PROMO_CODE_CUSTOMER_TYPE = 'FEATURE_SWITCH_PROPAGATE_PROMO_CODE_CUSTOMER_TYPE',
    FEATURE_SWITCH_ENGINE_COMPARATOR = 'FEATURE_SWITCH_ENGINE_COMPARATOR',
    FEATURE_SWITCH_POPULATE_CARTE_GRISE = 'FEATURE_SWITCH_POPULATE_CARTE_GRISE',
    FEATURE_SWITCH_SHOW_PRICE_EXCLUDED_VAT = 'FEATURE_SWITCH_SHOW_PRICE_EXCLUDED_VAT',
    FEATURE_SWITCH_CONFIGURABLE_TRIM_PAGE_VERSION = 'FEATURE_SWITCH_CONFIGURABLE_TRIM_PAGE_VERSION',
    FEATURE_SWITCH_AUTOMATIC_OPENING_FINANCE_WIDGET_ON_BASKET_PAGE_ENABLED = 'FEATURE_SWITCH_AUTOMATIC_OPENING_FINANCE_WIDGET_ON_BASKET_PAGE_ENABLED',
    FEATURE_SWITCH_FINANCE_WIDGET_VERSION = 'FEATURE_SWITCH_FINANCE_WIDGET_VERSION',
    FEATURE_SWITCH_FINANCE_WIDGET_SHADOW_DOM_ENABLED = 'FEATURE_SWITCH_FINANCE_WIDGET_SHADOW_DOM_ENABLED',
    FEATURE_SWITCH_GIGYA_ENABLED = 'FEATURE_SWITCH_GIGYA_ENABLED',
    FEATURE_SWITCH_POSTHOG_ENABLED = 'FEATURE_SWITCH_POSTHOG_ENABLED',
    FEATURE_SWITCH_POSTHOG_IS_AUTOCAPTURE_ENABLED = 'FEATURE_SWITCH_POSTHOG_IS_AUTOCAPTURE_ENABLED',
    FEATURE_SWITCH_AGENT_LOGIN_ENABLED = 'FEATURE_SWITCH_AGENT_LOGIN_ENABLED',
}

export interface IFeatureSwitchProps {
    features: IFeatureSwitchContext;
}

export const FeatureSwitchContext = React.createContext<IFeatureSwitchContext & IExperimentalFeatureFlagContext>({});

export const features = (cookies = Cookies.get()): IFeatureSwitchContext => {
    const cookie = cookies[FEATURE_SWITCHES_COOKIE_NAME];
    const isFeatureSwitchesEnabled = !JSON.parse(process.env.NEXT_PUBLIC_FEATURE_SWITCHES_COOKIE_DISABLED ?? null);

    let featuresFromCookies = {};
    if (cookie && isFeatureSwitchesEnabled) {
        featuresFromCookies = decodeFromBase64<IFeatureSwitchContext>(cookie);
    }

    const featureSwitchesIndividualFromEnv: { [key: string]: string } = Object.entries(process.env).reduce(
        (features: { [key: string]: string }, [key, value]: [string, any]) => {
            if (key.includes('FEATURE_')) {
                let parsedValue: any = value;
                try {
                    // for boolean,s json strings...
                    parsedValue = JSON.parse(value);
                } catch (error: any) {}
                features[key.replace('NEXT_PUBLIC_', '')] = parsedValue;
            }
            return features;
        },
        {}
    );

    const featuresFromEnv = decodeFromBase64<IFeatureSwitchContext>(process.env.NEXT_PUBLIC_FEATURE_SWITCHES);

    return { ...featuresFromEnv, ...featureSwitchesIndividualFromEnv, ...featuresFromCookies };
};

export const FeatureSwitchForApp: FunctionComponent<IFeatureSwitchProps> = (props) => {
    const { features, children } = props;

    return <FeatureSwitchContext.Provider value={features}>{children}</FeatureSwitchContext.Provider>;
};

export const FeatureSwitchForTesting: FunctionComponent<IFeatureSwitchProps> = (props) => {
    const { features, children } = props;

    return <FeatureSwitchContext.Provider value={features}>{children}</FeatureSwitchContext.Provider>;
};

export default FeatureSwitchForApp;
